import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useFunctionCall, percentFormat } from '@aldridge/aldg-helpers';
import { useRecoilValue } from 'recoil';
import dayjs from 'dayjs';
import TextDisplay from '../../UIComponents/TextDisplay';
import { roundedFormatAsCurrency } from '../../UIComponents/format';
import existsWithLength from '../../../utils/existsWithLength';
import { _Defaults } from '../../../_Recoil/atoms';

const ContractDetails = (props) => {
  const { Transaction, OriginalContract, ContractAdjustments, monthEndDate } = props;
  const Defaults = useRecoilValue(_Defaults);
  const { data, loading, functionCall } = useFunctionCall('AccountingDetail', { jctdscid: Transaction.id, monthEnd: monthEndDate, process: 'D' });

  useEffect(() => {
    let mounted = true;
    if (Transaction.id !== '' && mounted) functionCall({ jctdscid: Transaction.id, monthEnd: monthEndDate, process: 'D' });
    return () => {
      mounted = false;
    };
  }, [Transaction]);

  return (
    <Grid container className='aldg-bordered'>
      <Grid item xs={12} className='aldg-headerTitle'>
        Contract Details
      </Grid>
      <Grid item xs={4}>
        <TextDisplay label='Original Contract' value={roundedFormatAsCurrency(OriginalContract)} />
        <TextDisplay label='Contract Adjustments' value={roundedFormatAsCurrency(ContractAdjustments)} />
        <TextDisplay label='Current Contract Value' value={roundedFormatAsCurrency(OriginalContract + ContractAdjustments)} />
      </Grid>
      <Grid item xs={4}>
        <TextDisplay label='Retention?' value={!loading && existsWithLength(data) ? roundedFormatAsCurrency(data[0].Retention) : 'Loading...'} />
        <TextDisplay label='Retention %?' value={percentFormat(Transaction.RetentionPercent) || percentFormat(Defaults.RetentionPercent)} />
      </Grid>
      <Grid item xs={4}>
        <TextDisplay label='Job Start Date' value={Transaction.EcmsStartDate} />
        <TextDisplay label='Original Completion Date' value={Transaction.EcmsOriginalCompletionDate} />
        <TextDisplay
          label='Cash Flow Completion Date'
          value={dayjs(Transaction?.CashFlowCompletionDate).isValid() ? dayjs(Transaction?.CashFlowCompletionDate).format('YYYY-MM-DD') : ''}
        />
      </Grid>
    </Grid>
  );
};

ContractDetails.propTypes = {
  Transaction: PropTypes.object.isRequired,
  OriginalContract: PropTypes.number,
  ContractAdjustments: PropTypes.number,
  monthEndDate: PropTypes.string
};
ContractDetails.defaultProps = {
  OriginalContract: 0,
  ContractAdjustments: 0,
  monthEndDate: '2022-12-31'
};

export default ContractDetails;
