import React, { useState } from 'react';
import { Grid, Paper, Card, CardHeader, CardContent, Button } from '@mui/material';
import { doc, setDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';
// eslint-disable-next-line camelcase
import { useRecoilValue, useRecoilRefresher_UNSTABLE } from 'recoil';
import existsWithLength from '../utils/existsWithLength';
import PageHeader from '../Components/UIComponents/PageHeader';
import DefaultUpdater from '../Components/UIComponents/DefaultUpdater';
import NaNtoZero from '../utils/convertNaNToZero';
import { _CostDiffThreshold, _Defaults, _StatusMonth } from '../_Recoil/atoms';
import { firestore } from '../firebase';
import MonthEndOpts from '../Components/UIComponents/MonthEndOpts';

const CompanyDefaults = () => {
  const Defaults = useRecoilValue(_Defaults);
  const refreshDefaults = useRecoilRefresher_UNSTABLE(_Defaults);
  const [DefaultsChange, setDefaultsChange] = useState(Defaults);

  const CurrStatusMonth = useRecoilValue(_StatusMonth);
  const refreshCurrStatusMonth = useRecoilRefresher_UNSTABLE(_StatusMonth);
  const [CurrStatusMonthChange, setCurrStatusMonthChange] = useState(CurrStatusMonth);

  const CostDiff = useRecoilValue(_CostDiffThreshold);
  const [CostDiffChange, setCostDiffChange] = useState(CostDiff);

  const isStatus = true;

  const handleChange = (value, name, number) => {
    console.log(value, name, number);
    if (name === 'StatusMonth') setCurrStatusMonthChange(value);
    else if (['CostDiffLower', 'CostDiffUpper'].includes(name)) {
      const cd = { ...CostDiff };
      cd[name] = value;
      setCostDiffChange(cd);
    }
    if (!['CostDiffLower', 'CostDiffUpper', 'StatusMonth'].includes(name)) {
      const updatedTransaction = JSON.parse(JSON.stringify(DefaultsChange));
      if (number) updatedTransaction[name] = NaNtoZero(value);
      else updatedTransaction[name] = value;
      setDefaultsChange(updatedTransaction);
    }
  };

  const updateValue = (collection, field) => {
    let value = '';
    if (field === 'StatusMonth') value = CurrStatusMonthChange;
    else if (field === 'CostDiffLower') value = CostDiffChange.CostDiffLower;
    else if (field === 'CostDiffUpper') value = CostDiffChange.CostDiffUpper;
    else value = DefaultsChange[field];
    if (existsWithLength(value)) {
      setDoc(doc(firestore, `CompanyDefaults/${collection}`), { [`${field}`]: value }, { merge: true });
      toast.success('Updated successfully!');
    } else toast.error('There was a problem updating the value. Please try again.');
  };

  return (
    <Paper variant='outlined' className='aldg-field'>
      <Grid container style={{ backgroundColor: 'white', height: '100%', borderRadius: '4px', display: 'flex', alignContent: 'flex-start' }}>
        <Grid item xs={12}>
          <PageHeader title='Company Defaults' />
        </Grid>
        <Grid item xs={4}>
          <Card>
            <CardHeader title='Budgets' />
            <CardContent>
              <DefaultUpdater
                RecoilRefresher={refreshDefaults}
                transaction={DefaultsChange.ContractGLRate}
                field='ContractGLRate'
                label='Contract GL Rate'
                handleChange={handleChange}
                updateValue={() => updateValue('Accounting', 'ContractGLRate')}
                isPercent
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card>
            <CardHeader title='Cash Flow' />
            <CardContent>
              <DefaultUpdater
                RecoilRefresher={refreshDefaults}
                transaction={DefaultsChange.RetentionPercent}
                field='RetentionPercent'
                label='Retention %'
                handleChange={handleChange}
                updateValue={() => updateValue('Accounting', 'RetentionPercent')}
              />
              <DefaultUpdater
                RecoilRefresher={refreshDefaults}
                transaction={DefaultsChange.DSOModel}
                field='DSOModel'
                label='DSO Model (months)'
                handleChange={handleChange}
                updateValue={() => updateValue('Accounting', 'DSOModel')}
              />
              <DefaultUpdater
                RecoilRefresher={refreshDefaults}
                transaction={DefaultsChange.LaborEquipOther}
                field='LaborEquipOther'
                label='Labor, Equipment, and Other (months)'
                handleChange={handleChange}
                updateValue={() => updateValue('Accounting', 'LaborEquipOther')}
              />
              <DefaultUpdater
                RecoilRefresher={refreshDefaults}
                transaction={DefaultsChange.Materials}
                field='Materials'
                label='Materials (months)'
                handleChange={handleChange}
                updateValue={() => updateValue('Accounting', 'Materials')}
              />
              <DefaultUpdater
                RecoilRefresher={refreshDefaults}
                transaction={DefaultsChange.Subs}
                field='Subs'
                label='Subcontractors (months)'
                handleChange={handleChange}
                updateValue={() => updateValue('Accounting', 'Subs')}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card>
            <CardHeader title='Forecasting' />
            <CardContent>
              <DefaultUpdater
                RecoilRefresher={refreshDefaults}
                transaction={DefaultsChange.Variance}
                field='Variance'
                label='Variance'
                handleChange={handleChange}
                updateValue={() => updateValue('Accounting', 'Variance')}
                isPercent
              />
              <DefaultUpdater
                RecoilRefresher={refreshDefaults}
                transaction={DefaultsChange.Variance}
                field='ToolingAllocation'
                label='Tooling Allocation'
                handleChange={handleChange}
                updateValue={() => updateValue('Accounting', 'Variance')}
                isPercent
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card>
            <CardHeader title='Status Month' />
            <CardContent>
              <Grid item xs={12} style={{ padding: '12px', display: 'flex' }}>
                <MonthEndOpts monthEndDate={CurrStatusMonthChange} settingStatusMonth={isStatus} updateStatusMonth={handleChange} />
                <Button
                  variant='contained'
                  onClick={() => {
                    updateValue('StatusMonth', 'StatusMonth');
                    refreshCurrStatusMonth();
                  }}
                  style={{ height: '36px', alignSelf: 'flex-end', marginLeft: '12px' }}
                >
                  Update
                </Button>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card>
            <CardHeader title='Jobs to Status Cost Difference' />
            <CardContent>
              <DefaultUpdater
                RecoilRefresher={refreshDefaults}
                transaction={CostDiff.CostDiffLower}
                field='CostDiffLower'
                label='Lower Threshold ($)'
                handleChange={handleChange}
                updateValue={() => updateValue('JobsToStatus', 'CostDiffLower')}
              />
              <DefaultUpdater
                RecoilRefresher={refreshDefaults}
                transaction={CostDiff.CostDiffUpper}
                field='CostDiffUpper'
                label='Upper Threshold ($)'
                handleChange={handleChange}
                updateValue={() => updateValue('JobsToStatus', 'CostDiffUpper')}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CompanyDefaults;
