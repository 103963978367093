/* eslint-disable no-unused-vars */
import { collection, doc, getDoc, setDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { parseCellVal } from '../../../_helpers/ExcelJSHelpers';
import existsWithLength from '../../../utils/existsWithLength';
import { firestore } from '../../../firebase';

const mapCells = (row) =>
  row?._cells?.map((cell) => {
    if (cell.value === null || cell.value === undefined) {
      return '';
    }
    if (dayjs(cell.value).isValid() && cell._address.includes('C')) {
      const hourCorrect = dayjs(cell.value).add(6, 'hours');
      return dayjs(hourCorrect).endOf('month').format('YYYY-MM-DD');
    }
    return parseCellVal(cell);
  });

const uploadNotes = async (workbook, jctdscid, monthEndDate, user, SecurityLevel) => {
  const NotesWorksheet = workbook._worksheets.find((sheet) => typeof sheet !== 'undefined' && sheet.name === 'Notes');

  if (existsWithLength(NotesWorksheet)) {
    const firestorePromises = [];
    const Headers = mapCells(NotesWorksheet?._rows?.[0]).map((h) => h.replace(/\W/g, ''));

    let count = 0;
    await NotesWorksheet?._rows?.forEach(async (row, idx) => {
      if (idx > 0) {
        const rowVals = {};
        mapCells(row)?.forEach((cell, colIdx) => (rowVals[Headers[colIdx]] = cell || ''));
        if (existsWithLength(rowVals.Note)) {
          const ProperLinks = {
            jobinformation: 'JobInformation',
            agenda: 'Agenda',
            projectstatusreport: 'StatusReport',
            statusreport: 'StatusReport',
            forecasting: 'Forecasting',
            budgets: 'Budgets',
            changeorders: 'Budgets',
            cashflow: 'CashFlow',
            underbilling: 'Underbilling',
            notes: 'Notes',
            actionitems: 'ActionItems',
            userassignment: 'UserAssignment',
            attachments: 'Attachments',
            contractabstract: 'ContractAbstract',
            monthrollback: 'MonthRollback'
          };
          const ref = Object.keys(ProperLinks).includes(rowVals.ReferencePage?.replaceAll(' ', '').toLowerCase())
            ? rowVals.ReferencePage?.replaceAll(' ', '').toLowerCase()
            : '';

          const link = ref !== '' ? ProperLinks[ref] : '';
          const statusJob = rowVals.CompanyNumber && rowVals.JobNumber ? `${rowVals.CompanyNumber}.0.${rowVals.JobNumber}` : jctdscid;
          const monthEnd = rowVals?.MonthEnd !== '' ? rowVals?.MonthEnd : monthEndDate;

          const docRef = doc(collection(firestore, `ENT-Jobs/${statusJob}/Notes`));
          const docCheck = doc(firestore, 'ENT-Jobs', statusJob);
          let validJob = true;
          await getDoc(docCheck).then((d) => {
            if (!d.exists()) {
              validJob = false;
            }
          });

          if (validJob) {
            count += 1;
            const noteRecord = {
              MonthEnd: monthEnd,
              NoteAuthor: user.name,
              NoteOwner: user.email,
              Notes: rowVals.Note || '',
              Role: SecurityLevel,
              jctdscid: statusJob,
              LinkTo: link !== '' ? `/Job/${statusJob}/${link}` : '',
              id: `${docRef.id}-upload`,
              CreatedDate: new Date().toJSON(),
              CreatedBy: user.email,
              ModifiedDate: new Date().toJSON(),
              ModifiedBy: user.email
            };
            console.log(idx, noteRecord);

            firestorePromises.push(setDoc(doc(firestore, `ENT-Jobs/${statusJob}/Notes`, noteRecord.id), noteRecord, { merge: true }));
          } else {
            toast.error(
              `[Line ${idx + 1}] The job [${statusJob}] does not exist. Please make sure you have the correct job number and company number.`,
              {
                autoClose: false
              }
            );
          }
        } else {
          count += 1;
        }
      }
      console.log(`id ${idx}: ${count} ?= ${NotesWorksheet._rows.length - 1}`, firestorePromises);
      if (count === NotesWorksheet._rows.length - 1) {
        Promise.all(firestorePromises).then(() => toast.success(`Uploaded ${firestorePromises.length} notes successfully!`));
      }
    });
    // Promise.all(firestorePromises).then(() => toast.success(`Uploaded ${firestorePromises.length} notes successfully!`));
  } else {
    toast.error('Your spreadsheet is missing the required sheet. Please make sure you uploaded the correct file.');
  }
};

export default uploadNotes;
