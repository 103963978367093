import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid, useMediaQuery, createTheme } from '@mui/material';
import { doc, collection, setDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { _Clickstream, _SecurityLevel } from '../../../_Recoil/atoms';
import { firestore } from '../../../firebase';
import JobTimeline from './JobTimeline';
import JobInfo from './JobInfo';
import ProjectOwnership from './ProjectOwnership';
import ProjectLocation from './ProjectLocation';
import ContractWork from './ContractWork';
import Accounting from './Accounting';
import WorkDetails from './WorkDetails';
import Safety from './Safety';
import Subcontractors from './Subcontractors';
import { UserContext } from '../../../providers/UserProvider';
import ToggleEditCard from '../../UIComponents/ToggleEditCard';
import { EditorUp, isProjectAccountant } from '../UserAssignment/checkSecurity';

const JobDetails = (props) => {
  const { Transaction, onChange, Defaults, setDefaults } = props;
  const matches = useMediaQuery(createTheme().breakpoints.down('md'));
  const [edit, setEdit] = useState('');
  const [transactionChanged, setTransactionChanged] = useState(false);
  const user = useContext(UserContext);
  const SecurityLevel = useRecoilValue(_SecurityLevel);
  const Clickstream = useRecoilValue(_Clickstream);

  const inAgenda = Clickstream.inAgenda;

  const detectOnChange = (event, name, displayFromTypeahead) => {
    setTransactionChanged(true);
    onChange(event, name, displayFromTypeahead);
  };

  const handleSave = () => {
    if (!transactionChanged) return;
    try {
      if (Transaction.id === '') {
        const docRef = doc(collection(firestore, 'ENT-Jobs'));
        Transaction.id = docRef.id;
        setDoc(docRef, Transaction, { merge: true }).catch((err) => {
          toast.error(err);
        });
      } else {
        const docRef = doc(collection(firestore, 'ENT-Jobs'), Transaction.id);
        setDoc(docRef, Transaction, { merge: true }).catch((err) => {
          toast.error(err);
        });
      }
    } catch (err) {
      toast.error(err.toString());
    }
    toast.success('Record saved!');
    setTransactionChanged(false);
  };

  const pageArray = [
    {
      component: <JobInfo Transaction={Transaction} onChange={detectOnChange} editing={edit.includes('Job Name and Number')} />,
      col: 1,
      title: 'Job Name and Number',
      canEdit: EditorUp(SecurityLevel)
    },
    {
      component: <ProjectOwnership Transaction={Transaction} onChange={detectOnChange} editing={edit.includes('Project Ownership')} />,
      col: 1,
      title: 'Project Ownership',
      canEdit: EditorUp(SecurityLevel)
    },
    {
      component: <ProjectLocation Transaction={Transaction} onChange={detectOnChange} editing={edit.includes('Project Location')} user={user} />,
      col: matches ? 1 : 2,
      title: 'Project Location',
      canEdit: EditorUp(SecurityLevel)
    },
    {
      component: <ContractWork Transaction={Transaction} onChange={detectOnChange} editing={edit.includes('Contract/Work Type')} />,
      col: 1,
      title: 'Contract/Work Type',
      canEdit: EditorUp(SecurityLevel)
    },
    {
      component: <JobTimeline Transaction={Transaction} onChange={detectOnChange} editing={edit.includes('Timeline')} user={user} />,
      col: matches ? 1 : 2,
      title: 'Timeline',
      canEdit: EditorUp(SecurityLevel)
    },
    {
      component: <WorkDetails Transaction={Transaction} onChange={detectOnChange} editing={edit.includes('Project Team')} matches={matches} />,
      col: 1,
      title: 'Project Team',
      canEdit: false
    },
    {
      component: <Safety Transaction={Transaction} onChange={detectOnChange} editing={edit.includes('Safety')} />,
      col: matches ? 1 : 2,
      title: 'Safety',
      canEdit: EditorUp(SecurityLevel)
    },
    {
      component: (
        <Accounting
          Transaction={Transaction}
          onChange={detectOnChange}
          editing={edit.includes('Accounting')}
          Defaults={Defaults}
          setDefaults={setDefaults}
        />
      ),
      col: matches ? 1 : 2,
      title: 'Accounting',
      canEdit: EditorUp(SecurityLevel) || isProjectAccountant(SecurityLevel)
    },
    {
      component: <Subcontractors Transaction={Transaction} onChange={detectOnChange} editing={edit.includes('Subcontractors')} user={user} />,
      col: 1,
      title: 'Subcontractors',
      canEdit: false
    }
  ];

  const toggleEdit = (index) => {
    if (edit === index.toString()) setEdit('');
    else setEdit(index.toString());
  };

  return (
    <Grid container>
      <Grid item xs={12} md={8}>
        {pageArray
          .filter((page) => page.col === 1)
          .map((section, idx, arr) => (
            <React.Fragment key={section.title}>
              <ToggleEditCard
                section={section}
                edit={edit}
                toggleEdit={toggleEdit}
                handleSave={handleSave}
                allowEdit={!inAgenda && section.canEdit}
              />
              {idx < arr.length - 1 && <div style={{ margin: '-16px 8px 24px 8px', outline: '1px solid #d8262f' }} />}
            </React.Fragment>
          ))}
      </Grid>
      {!matches && (
        <Grid item xs={12} md={4} style={{ backgroundColor: '#dedede', borderRadius: '4px' }}>
          {pageArray
            .filter((page) => page.col === 2)
            .map((section) => (
              <ToggleEditCard
                section={section}
                edit={edit}
                toggleEdit={toggleEdit}
                key={section.title}
                color='#dedede'
                allowWrap
                allowEdit={!inAgenda && section.canEdit}
                handleSave={handleSave}
              />
            ))}
        </Grid>
      )}
    </Grid>
  );
};

JobDetails.propTypes = {
  Transaction: PropTypes.object,
  onChange: PropTypes.func,
  Defaults: PropTypes.object,
  setDefaults: PropTypes.func
};
JobDetails.defaultProps = {
  Transaction: { data: {} },
  onChange: () => {},
  Defaults: {},
  setDefaults: () => {}
};

export default JobDetails;
