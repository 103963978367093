import { doc, onSnapshot } from 'firebase/firestore';
import { firestore } from '../firebase';

const getCompanyDefaults = async () =>
  new Promise((resolve) => {
    onSnapshot(doc(firestore, `CompanyDefaults/Accounting`), (d) => {
      resolve(d.data());
    });
  });

export default getCompanyDefaults;
