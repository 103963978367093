/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, useTheme, useMediaQuery } from '@mui/material';
import { collection, collectionGroup, where, orderBy, query, getDocs, getDoc, doc } from 'firebase/firestore';
import { AldgDataGrid, InputAutocomplete, InputSelect } from '@aldridge/aldg-data-components';
// import { useSetRecoilState } from 'recoil';
import { useGridApiRef } from '@mui/x-data-grid-premium';
import { UserContext } from '../providers/UserProvider';
import existsWithLength from '../utils/existsWithLength';
// import { _Defaults } from '../_Recoil/atoms';
import { firestore } from '../firebase';
import ErrorBoundary from '../_GlobalComponents/ErrorBoundary';

const JobsTable = () => {
  const [Jobs, setJobs] = useState([]);
  const [JobStatus, setJobStatus] = useState('Open');
  // const setDefaults = useSetRecoilState(_Defaults);
  const user = useContext(UserContext);
  const [Employee, setEmployee] = useState([]);
  const apiRef = useGridApiRef();

  useEffect(() => {
    let mounted = true;
    try {
      if (!firestore._terminated) {
        const allJobs = query(collection(firestore, 'ENT-Jobs'), where('EcmsSubJobNumber', '==', '   '), orderBy('EcmsJobNumber'));
        const impersonate = Employee.length > 0 ? Employee[0] : user.email;
        const specificJobs = query(collectionGroup(firestore, `UserAssignment`), where('Email', '==', impersonate));
        getDoc(doc(firestore, 'Users', impersonate)).then((d) => {
          const userData = d.data();
          getDocs(allJobs).then((docs) => {
            const all = [];
            docs.forEach((allJobDocs) => {
              const jobinfo = allJobDocs.data();
              all.push(jobinfo);
            });
            getDocs(specificJobs).then((docss) => {
              const allIds = [];
              if (userData.globalRole === 'Division') {
                userData?.Divisions?.forEach((div) => {
                  allIds.push(...all.filter((a) => a.EcmsDivisionNumber === div));
                });
              }
              docss.forEach((specificDocs) => {
                const data = specificDocs.data();
                const idx = all.findIndex((a) => a.id === data.Job[0]);
                const alreadyExistsIdx = allIds.findIndex((a) => a.id === data.Job[0]);
                if (idx > -1 && alreadyExistsIdx === -1) {
                  allIds.push({
                    EcmsJobNumber: data.Job[0],
                    id: data.Job[0],
                    CosentialJobName: all[idx].CosentialJobName,
                    EcmsDivisionNumber: all[idx].EcmsDivisionNumber,
                    EcmsStatusSimplified: all[idx].EcmsStatusSimplified,
                    EcmsJobDescription: all[idx].EcmsJobDescription
                  });
                }
              });
              if (mounted) {
                if (
                  (user.admin || user.SuperAdmin || (existsWithLength(user.globalRole) && ['none', 'Division'].indexOf(user.globalRole) === -1)) &&
                  Employee.length === 0 &&
                  mounted
                ) {
                  setJobs(all);
                } else if (mounted) {
                  setJobs(allIds);
                }
              }
            });
          });
        });
        // getDoc(doc(firestore, 'CompanyDefaults', 'Accounting')).then((d) => {
        //   if (mounted) setDefaults(d.data());
        // });
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
    return () => {
      mounted = false;
    };
  }, [Employee]);

  const history = useHistory();

  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const theme = useTheme();

  console.log('JOBS', filteredRows);
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (JobStatus === 'All Jobs') setFilteredRows(rows);
      else setFilteredRows(rows.filter((r) => r.EcmsStatusSimplified === JobStatus));
    }
    return () => (mounted = false);
  }, [JobStatus]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setRows(Jobs);
      setFilteredRows(Jobs.filter((r) => r.EcmsStatusSimplified === 'Open'));
    }
    return () => (mounted = false);
  }, [Jobs]);

  const getFlex = (f) => (matches ? null : f);
  const getWidth = (w) => (matches ? w : null);

  const handleSelect = (idx) => {
    history.push(`/Job/${idx}`);
  };

  const columns = [
    {
      field: 'JobNumber',
      headerName: 'Job Number',
      valueGetter: (params) => `${params.row.EcmsJobNumber}${existsWithLength(params.row.EcmsSubJobNumber) ? `.${params.row.EcmsSubJobNumber}` : ''}`,
      flex: getFlex(10),
      width: getWidth(200)
    },
    {
      field: 'DivisionNumer',
      headerName: 'Division Number',
      valueGetter: (params) => `${params.row.EcmsDivisionNumber}`,
      flex: getFlex(10),
      width: getWidth(200)
    },
    {
      field: 'ProjectDescription',
      headerName: 'Description',
      valueGetter: (params) => (existsWithLength(params.row.CosentialJobName) ? params.row.CosentialJobName : params.row.EcmsJobDescription),
      flex: getFlex(15),
      width: getWidth(300)
    },
    {
      field: 'CompanyNumber',
      headerName: 'Company',
      valueGetter: (params) => params.row.EcmsCompanyNumber || '',
      width: getWidth(10)
    },
    {
      field: 'Status',
      headerName: 'Status',
      valueGetter: (params) => `${params.row.EcmsStatusSimplified}`,
      flex: getFlex(5),
      width: getWidth(30),
      sortable: false,
      type: 'singleSelect',
      valueOptions: ['Open', 'Closed'],
      defaultValue: ''
    }
  ];
  return (
    <ErrorBoundary componentName='Jobs Table'>
      <Grid container>
        {user.admin && (
          <Grid item xs={10}>
            <InputAutocomplete
              firestoreOptions={{
                valueKey: 'Email',
                collection: 'ENT-Employees',
                where: [['Status', '==', 'A']],
                method: 'onSnapshot'
              }}
              label='See Jobs for Employee'
              name='Employee'
              onChange={(a) => {
                setEmployee(a);
              }}
              optionDisplay={(opt) => `${opt.PreferredFirstName} ${opt.MiddleName} ${opt.PreferredLastName} [${opt.Title}]`}
              value={Employee || []}
            />
          </Grid>
        )}
        <Grid item xs={2}>
          <InputSelect
            name='Job Status'
            label='Job Status Filter'
            onChange={(e) => setJobStatus(e.target.value)}
            value={JobStatus}
            options={[
              { value: 'Open', label: 'Open' },
              { value: 'Closed', label: 'Closed' },
              { value: 'All Jobs', label: 'All Jobs' }
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <AldgDataGrid
            apiRef={apiRef}
            containerHeight='750px'
            pagination
            columns={columns}
            onRowClick={(row) => handleSelect(row.id)}
            rows={filteredRows}
            loading={rows.length === 0}
          />
        </Grid>
      </Grid>
    </ErrorBoundary>
  );
};

JobsTable.propTypes = {};
JobsTable.defaultProps = {};

export default JobsTable;
