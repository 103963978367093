import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { InputDate, InputTextarea } from '@aldridge/aldg-data-components';
import { collection, doc, getDocs, setDoc } from 'firebase/firestore';
import { useRecoilValue } from 'recoil';
import { Popper, Typography } from '@mui/material';
import dayjs from 'dayjs';
import AldgModal from '../../UIComponents/AldgModal';
import { UserContext } from '../../../providers/UserProvider';
import { getPM, getOwner } from '../../../utils/getUserWithRole';
import existsWithLength from '../../../utils/existsWithLength';
import { _MonthEndDate, _SecurityLevel } from '../../../_Recoil/atoms';
import { firestore } from '../../../firebase';
import WillLinkToDiv from '../Dashboard/WillLinkToDiv';
import { AdminUp } from '../UserAssignment/checkSecurity';

const QuickAddActionItems = (props) => {
  const { jctdscid, open, handleClose, location, memorialized } = props;
  const [item, setItem] = useState('');
  const [dueDate, setDueDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [pm, setPM] = useState('');
  const [owner, setOwner] = useState('');
  const user = useContext(UserContext);
  const CurrentMonth = useRecoilValue(_MonthEndDate);
  const role = useRecoilValue(_SecurityLevel);
  const canAddAI = !memorialized || AdminUp(role);

  useEffect(() => {
    let mounted = true;

    getDocs(collection(firestore, `ENT-Jobs/${jctdscid}/UserAssignment`)).then((docs) => {
      const allDocs = [];
      docs.forEach((d) => allDocs.push(d.data()));
      if (mounted) setPM(getPM(allDocs));
      if (mounted) setOwner(getOwner(allDocs));
    });

    return () => {
      mounted = false;
    };
  }, [jctdscid]);

  const handleSave = () => {
    const docRef = doc(collection(firestore, `ENT-Jobs/${jctdscid}/ActionItems`));
    const aiRecord = {
      CreatedBy: user.email,
      CreatedDate: new Date().toJSON(),
      Creator: user.name,
      PM: pm || '',
      Owner: owner || '',
      ActionItem: item,
      DueDate: dueDate,
      CompletionStatus: 'Incomplete',
      LinkTo: location,
      id: docRef.id,
      JobID: jctdscid,
      MonthEndAdded: CurrentMonth
    };
    if (existsWithLength(item))
      setDoc(doc(firestore, `ENT-Jobs/${jctdscid}/ActionItems/${aiRecord.id}`), aiRecord, {
        merge: true
      });
    setItem('');
    // setOwner('');
    // setPM('');
    setDueDate(dayjs().format('YYYY-MM-DD'));
    handleClose();
  };

  const lockedChildren = [
    {
      content: (
        <Typography>{`This month is memorialized and no new action items can be added. Go to an unlocked month if you want to add an action item. `}</Typography>
      ),
      key: 'notice'
    },
    {
      type: 'actions',
      key: 'buttons',
      buttons: [
        {
          text: 'Close',
          onClick: () => {
            setItem('');
            handleClose();
          },
          color: 'error'
        }
      ]
    }
  ];

  const children = [
    {
      type: 'text',
      content: 'Action Item',
      key: 'ai textarea label'
    },
    {
      content: <InputTextarea name='Action Item' value={item} onBlur={(e) => setItem(e)} />,
      key: 'ai textearea'
    },
    {
      type: 'text',
      content: 'Due Date',
      key: 'ai date label'
    },
    {
      content: (
        <InputDate
          fullWidth
          name='Due Date'
          value={dueDate}
          slots={{ popper: Popper }}
          slotProps={{ popper: { style: { zIndex: 20000 } } }}
          onChange={(e) => {
            setDueDate(e);
          }}
        />
      ),
      key: 'ai date'
    },
    {
      content: <WillLinkToDiv type='action item' location={location} />,
      key: 'note linkto'
    },
    {
      type: 'actions',
      key: 'buttons',
      buttons: [
        {
          text: 'Cancel',
          onClick: () => {
            setItem('');
            handleClose();
          },
          color: 'error'
        },
        {
          text: 'Save',
          onClick: handleSave
        }
      ]
    }
  ];

  return (
    <AldgModal title={`Add Action Item (${CurrentMonth})`} open={open} handleClose={handleClose} pieces={canAddAI ? children : lockedChildren} />
  );
};

QuickAddActionItems.propTypes = {
  jctdscid: PropTypes.string.isRequired,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  location: PropTypes.string,
  memorialized: PropTypes.bool
};
QuickAddActionItems.defaultProps = {
  open: false,
  handleClose: () => {},
  location: '',
  memorialized: false
};

export default QuickAddActionItems;
