import React from 'react';
import PropTypes from 'prop-types';
import { Grid, useMediaQuery, createTheme } from '@mui/material';
import { AldgDataGrid } from '@aldridge/aldg-data-components';
import { useRecoilValue } from 'recoil';
import { roundedFormatAsCurrency } from '../../UIComponents/format';
import { _Defaults } from '../../../_Recoil/atoms';
import CostType from './sharedColumns';

const ProjectedAnalysisStatus = (props) => {
  const { CurrentContractValue, laborPT, materialPT, subPT, equipPT, otherPT, totalPT, Defaults } = props;

  const matches = useMediaQuery(createTheme().breakpoints.down('lg'));
  const CompanyDefaults = useRecoilValue(_Defaults);

  const getFlex = (f) => (matches ? null : f);
  const getWidth = (w) => (matches ? w : null);

  const row = [
    {
      id: '1',
      Labor: laborPT,
      Material: materialPT,
      Subcontracts: subPT,
      Equipment: equipPT,
      Other: otherPT,
      Total: totalPT,
      TotalNetCashW: CurrentContractValue - CurrentContractValue * (Defaults?.RetentionPercent || CompanyDefaults.RetentionPercent) - totalPT,
      TotalNetCashWO: CurrentContractValue - totalPT
    }
  ];

  const statusCols = ['Labor', 'Material', 'Subcontracts', 'Equipment', 'Other', 'Total']
    .map((type) => CostType(getWidth, getFlex, type))
    .concat([
      {
        field: 'TotalNetCashW',
        headerName: 'Total Net Cash W/ Corp. Cont',
        headerAlign: 'center',
        align: 'center',
        type: 'number',
        width: getWidth(200),
        flex: getFlex(200),
        valueFormatter: (params) => roundedFormatAsCurrency(params.value)
      },
      {
        field: 'TotalNetCashWO',
        headerName: 'Total Net Cash W/O Corp. Cont',
        headerAlign: 'center',
        align: 'center',
        type: 'number',
        flex: getFlex(200),
        width: getWidth(200),
        valueFormatter: (params) => roundedFormatAsCurrency(params.value)
      }
    ]);

  return (
    <Grid container className='aldg-bordered'>
      <Grid item xs={12} className='aldg-headerTitle'>
        Projected Cash Flow Analysis
      </Grid>
      <Grid item xs={12} className='aldg-subheaderTitle'>
        From Status
      </Grid>
      <Grid item xs={12}>
        <AldgDataGrid pageSizeOptions={[10, 20, 30]} columns={statusCols} rows={row} noToolbar />
      </Grid>
    </Grid>
  );
};

ProjectedAnalysisStatus.propTypes = {
  CurrentContractValue: PropTypes.number,
  laborPT: PropTypes.number,
  materialPT: PropTypes.number,
  subPT: PropTypes.number,
  equipPT: PropTypes.number,
  otherPT: PropTypes.number,
  totalPT: PropTypes.number,
  Defaults: PropTypes.object
};
ProjectedAnalysisStatus.defaultProps = {
  CurrentContractValue: 0,
  laborPT: 0,
  materialPT: 0,
  subPT: 0,
  equipPT: 0,
  otherPT: 0,
  totalPT: 0,
  Defaults: {}
};

export default ProjectedAnalysisStatus;
