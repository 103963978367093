/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, createTheme, useMediaQuery, Grid } from '@mui/material';
import { AldgDataGrid, InputSelect } from '@aldridge/aldg-data-components';
import { useRecoilState, useRecoilValue } from 'recoil';
import { toast } from 'react-toastify';
import { doc, setDoc, writeBatch } from 'firebase/firestore';
import { ToolTipIconButton } from '@aldridge/aldg-ui-components';
import { faList } from '@fortawesome/pro-regular-svg-icons';
import dayjs from 'dayjs';
import ErrorBoundary from '../../../_GlobalComponents/ErrorBoundary';
import { formatAsCurrency, formatShortDate } from '../../UIComponents/format';
import NaNtoZero from '../../../utils/convertNaNToZero';
import NotesButton from '../../UIComponents/NotesButton';
import { isApproved, isPending, isPendingNegative } from '../../../utils/isPending';
import { _ChangeOrderGridState, _SecurityLevel, _CurrentJob } from '../../../_Recoil/atoms';
import { firestore } from '../../../firebase';
import { AdminUp } from '../UserAssignment/checkSecurity';
import existsWithLength from '../../../utils/existsWithLength';
import DownloadECMS from './DownloadECMS';

const ChangeOrderTable = (props) => {
  const { changeOrders, handleSelect, apiRef, MonthEndDate } = props;
  const Transaction = useRecoilValue(_CurrentJob);
  const matches = useMediaQuery(createTheme().breakpoints.down('lg'));
  const SecurityLevel = useRecoilValue(_SecurityLevel);

  const [GridState, setGridState] = useRecoilState(_ChangeOrderGridState);
  const [StatusOption, setStatusOption] = React.useState('');
  const [LockBudget, setLockBudget] = React.useState('');

  const getFlex = (f) => (matches ? null : f);
  const getWidth = (w) => (matches ? w : null);

  const parsePrice = (co) => NaNtoZero(co?.ContractValue);

  const rows = changeOrders.map((co) => {
    const noData = { ...co?.data, id: co?.id };
    return noData;
  });

  const columns = [
    {
      field: 'CustomerChangeOrder',
      headerAlign: 'center',
      headerName: 'Customer #',
      valueGetter: (params) => params.row.CustomerChangeOrder || '',
      flex: getFlex(100),
      width: getWidth(150)
    },
    {
      field: 'AldridgeChangeOrder',
      headerAlign: 'center',
      headerName: 'Aldridge #',
      valueGetter: (params) => params.row.AldridgeChangeOrder || '',
      flex: getFlex(100),
      width: getWidth(150)
    },
    {
      field: 'ECMSChangeOrder',
      headerAlign: 'center',
      headerName: 'ECMS #',
      valueGetter: (params) => params.row.ECMSChangeOrder || '',
      flex: getFlex(100),
      width: getWidth(150)
    },
    {
      field: 'BudgetDescription',
      headerAlign: 'center',
      headerName: 'Budget Description',
      valueGetter: (params) => params.row.BudgetDescription,
      flex: getFlex(200),
      width: getWidth(150)
    },
    {
      field: 'DateSubmitted',
      headerAlign: 'center',
      headerName: 'Date Submitted',
      valueGetter: (params) => params.row.DateSubmitted,
      valueFormatter: (params) => formatShortDate(params.value),
      flex: getFlex(95),
      width: getWidth(85)
    },
    {
      field: 'ChangeOrderType',
      headerAlign: 'center',
      headerName: 'Budget Type',
      valueGetter: (params) => params?.row?.ChangeOrderType,
      flex: getFlex(150),
      width: getWidth(150),
      sortable: false,
      type: 'singleSelect',
      valueOptions: [
        '',
        'T & M Change Order',
        'Force Account Change Order',
        'Lump Sum Change Order',
        'Change Order Escalation',
        'Units Change Order',
        'Construction Budget',
        'Original Budget',
        'Pre-Start Budget',
        'Authorized Extras',
        'Claims'
      ],
      defaultValue: ''
    },
    {
      field: 'Status',
      flex: getFlex(120),
      width: getWidth(135),
      headerAlign: 'center',
      canEdit: true,
      tooltipText: 'Approved Statuses: Approved, Approved FE, Pending Negative. Pending Statuses: Pending Tracking, Pending Carried.'
    },
    {
      field: 'EstimatedValue',
      headerAlign: 'center',
      headerName: 'Estimated Value',
      type: 'number',
      availableAggregationFunctions: ['sum'],
      valueGetter: (params) => (params.row.Status !== 'Voided' ? NaNtoZero(params.row.EstimatedValue) : 0),
      valueFormatter: (params) => formatAsCurrency(params.value),
      flex: getFlex(140),
      width: getWidth(150),
      totalRow: true
    },
    {
      field: 'ContractValue',
      headerAlign: 'center',
      headerName: 'Amount',
      type: 'number',
      availableAggregationFunctions: ['sum'],
      valueGetter: (params) => (params.row.Status !== 'Voided' ? NaNtoZero(params.row.ContractValue) : 0),
      valueFormatter: (params) => formatAsCurrency(params.value),
      flex: getFlex(140),
      width: getWidth(150),
      totalRow: true
    },
    {
      field: 'Pending',
      headerAlign: 'center',
      valueGetter: (params) => {
        if (isPending(params?.row?.Status) || isPendingNegative(params?.row?.Status)) return parsePrice(params.row);
        return 0;
      },
      valueFormatter: (params) => formatAsCurrency(params.value),
      type: 'number',
      availableAggregationFunctions: ['sum'],
      flex: getFlex(140),
      width: getWidth(175),
      totalRow: true
    },
    {
      field: 'PendingCarried',
      headerName: 'Pending Carried in Status',
      headerAlign: 'center',
      valueGetter: (params) => {
        if ((isPending(params?.row?.Status) || isPendingNegative(params?.row?.Status)) && NaNtoZero(params.row.CarryAmount) > 0)
          return NaNtoZero(params.row.CarryAmount);
        if (isPending(params?.row?.Status) || isPendingNegative(params?.row?.Status)) return parsePrice(params.row);
        return 0;
      },
      valueFormatter: (params) => formatAsCurrency(params.value),
      type: 'number',
      availableAggregationFunctions: ['sum'],
      flex: getFlex(140),
      width: getWidth(175),
      totalRow: true
    },
    {
      field: 'Approved',
      headerAlign: 'center',
      valueGetter: (params) => {
        if (typeof params.row.Approved !== 'undefined') return NaNtoZero(params.row.Approved);
        if (isApproved(params?.row?.Status)) return parsePrice(params.row);
        return 0;
      },
      valueFormatter: (params) => formatAsCurrency(params.value),
      type: 'number',
      availableAggregationFunctions: ['sum'],
      flex: getFlex(140),
      width: getWidth(150),
      totalRow: true
    },
    {
      field: 'Status Log',
      headerAlign: 'center',
      valueGetter: (params) => params.row.StatusLog || [],
      renderCell: (params) =>
        params.id === 'TOTAL' ? null : (
          <ToolTipIconButton
            shape='circle'
            color={params.value.length > 0 ? 'primary' : 'gray'}
            tooltipText={
              existsWithLength(params.value)
                ? params.value.map((s) => `Status: ${s.NewStatus} - Date: ${dayjs(s.ModifiedDate).format('YYYY-MM-DD h:mm a')}\n`)
                : 'No Status Log'
            }
            icon={faList}
          />
        ),
      flex: getFlex(140),
      width: getWidth(150),
      align: 'center'
    },
    {
      field: 'Notes',
      headerAlign: 'center',
      valueGetter: (params) => params.row.Notes,
      renderCell: (params) => (params.id === 'TOTAL' ? null : <NotesButton note={params.value} />),
      sortable: false,
      flex: getFlex(65),
      width: getWidth(65),
      align: 'center'
    }
  ];

  const toggleValues = async (field) => {
    const selectedItems = Array.from(apiRef.current.getSelectedRows(), ([key, value]) => ({ key, value }));
    const filteredItems = selectedItems.filter((co) => co.value !== undefined);
    console.log(filteredItems);
    const batch = writeBatch(firestore);
    filteredItems.forEach((co) => {
      const docRef = doc(firestore, `ENT-Jobs/${co.value.JobID}/MonthEnd/${MonthEndDate}/ChangeOrders/${co.key}`);
      const dataUpdate = field === 'Status' ? { data: { Status: StatusOption } } : { MemorializedLock: LockBudget };
      batch.set(docRef, dataUpdate, { merge: true });
      if (field === 'Status') {
        if (['Approved', 'Approved FE'].includes(StatusOption)) {
          batch.set(docRef, { MemorializedLock: 'true' }, { merge: true });
        } else {
          batch.set(docRef, { MemorializedLock: 'false' }, { merge: true });
        }
      }
    });
    await batch.commit();
  };

  const GenerateECMSBudgetNumber = async (records) => {
    let newECMSNumber = Transaction?.EcmsBudgetNumber || 1;
    for (let i = 0; i < records.length; i++) {
      const record = changeOrders.find((c) => c.id === records[i].key);
      if (record?.data?.DateApproved !== '') {
        const newRecord = { ...record };
        newRecord.data.ECMSChangeOrder = `ALDG${`000${newECMSNumber}`.slice(-4)}`;
        // eslint-disable-next-line no-await-in-loop
        await setDoc(doc(firestore, `ENT-Jobs/${Transaction.id}/MonthEnd/${MonthEndDate}/ChangeOrders/${record.id}`), newRecord, { merge: true });
        newECMSNumber += 1;
      } else {
        toast.error('Please select a date approved before generating an ECMS number.');
      }
    }
    await setDoc(doc(firestore, `ENT-Jobs/${Transaction.id}`), { EcmsBudgetNumber: newECMSNumber + 1 }, { merge: true });
  };

  const GenerateBudgets = async () => {
    const arr = Array.from(apiRef?.current?.getSelectedRows(), ([key, value]) => ({ key, value }));
    if (arr.length === 0) {
      toast.error('No Budgets Selected.');
    } else if (arr.every((co) => !existsWithLength(co?.value?.ECMSChangeOrder))) {
      await GenerateECMSBudgetNumber(arr);
    } else {
      toast.error('You have some budgets that have already been generated. Please unselect them before continuing.');
    }
  };
  const DownloadBudgets = async () => {
    console.log(apiRef?.current?.getSelectedRows());
    const arr = Array.from(apiRef?.current?.getSelectedRows(), ([key, value]) => ({ key, value }));
    if (arr.length === 0) {
      toast.error('No Budgets Selected.');
    } else if (arr.every((co) => existsWithLength(co?.value?.ECMSChangeOrder))) {
      const allCOs = [];
      for (let i = 0; i < arr.length; i++) {
        const co = changeOrders.find((c) => c.id === arr[i].key);
        if (existsWithLength(co)) allCOs.push(co);
      }
      await DownloadECMS(Transaction, allCOs);
    } else {
      toast.error('You have some budgets that have not been generated. Please unselect them before continuing.');
    }
  };

  return (
    <ErrorBoundary componentName='Change Order Table'>
      {AdminUp(SecurityLevel) && (
        <>
          <Grid container>
            <Grid item xs={2}>
              <InputSelect
                name='Status'
                label='Status'
                onChange={(e) => setStatusOption(e.target.value)}
                value={StatusOption}
                options={[
                  { label: 'Pending Tracking', value: 'Pending Tracking' },
                  { label: 'Pending Not Carried', value: 'Pending Not Carried' },
                  { label: 'Pending Negative', value: 'Pending Negative' },
                  { label: 'Pending Carried', value: 'Pending Carried' },
                  { label: 'Approved', value: 'Approved' },
                  { label: 'Approved FE', value: 'Approved FE' },
                  { label: 'Voided', value: 'Voided' }
                ]}
              />
            </Grid>
            <Grid item xs={3}>
              <Button onClick={() => toggleValues('Status')} variant='contained' style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                Set Status
              </Button>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={2}>
              <InputSelect
                name='Lock Budget'
                label='Lock Budget'
                onChange={(e) => setLockBudget(e.target.value)}
                value={LockBudget}
                options={[
                  { value: 'true', label: 'Lock' },
                  { value: 'false', label: 'Unlock' }
                ]}
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                onClick={() => toggleValues('MemorializedLock')}
                variant='contained'
                style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}
              >
                Set Lock
              </Button>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={1}>
              <Button
                onClick={() => {
                  GenerateBudgets();
                }}
                variant='contained'
                style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}
                // disabled={!enableGenerate}
              >
                Generate
              </Button>
            </Grid>
            <Grid item xs={1}>
              <Button
                onClick={() => {
                  DownloadBudgets();
                }}
                variant='contained'
                style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}
                // disabled={!enableDownload}
              >
                Download
              </Button>
            </Grid>
          </Grid>
        </>
      )}
      <AldgDataGrid
        containerHeight='750px'
        pageSizeOptions={[10, 20, 30]}
        pinnedRows={{ bottom: undefined, top: undefined }}
        aggregationModel={{
          EstimatedValue: 'sum',
          ContractValue: 'sum',
          Pending: 'sum',
          Approved: 'sum',
          PendingCarried: 'sum'
        }}
        ToolbarProps={{
          canAdd: false,
          canDelete: false,
          showDownloads: true,
          csvOptions: {
            fileName: `${Transaction.id}_RFCO[${MonthEndDate}]`
          },
          excelOptions: {
            exceljsPreProcess: (workbook) => {
              workbook.name = `${Transaction.id}_RFCO[${MonthEndDate}]`;
            }
          }
        }}
        apiRef={apiRef}
        checkboxSelection={AdminUp(SecurityLevel)}
        onRowClick={(params) => {
          if (params.id !== 'TOTAL') {
            const fullCO = changeOrders.find((co) => co?.id === params.id);
            handleSelect(fullCO);
          }
        }}
        columns={columns}
        rows={rows}
        rowGroupingModel={['Status']}
        totalRow
        getRowId={(row) => row.id}
        gridState={{ gridAtom: GridState, setGridAtom: setGridState }}
      />
    </ErrorBoundary>
  );
};

ChangeOrderTable.propTypes = {
  changeOrders: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleSelect: PropTypes.func.isRequired,
  apiRef: PropTypes.any.isRequired,
  MonthEndDate: PropTypes.string.isRequired
};
ChangeOrderTable.defaultProps = {};

export default ChangeOrderTable;
