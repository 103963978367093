/* eslint-disable no-unused-vars */
import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { InputTextarea } from '@aldridge/aldg-data-components';
import { useRecoilValue } from 'recoil';
import { collection, doc, setDoc } from 'firebase/firestore';
import dayjs from 'dayjs';
import { Typography } from '@mui/material';
import { _MonthEndDate, _SecurityLevel } from '../../../_Recoil/atoms';
import { UserContext } from '../../../providers/UserProvider';
import AldgModal from '../../UIComponents/AldgModal';
import existsWithLength from '../../../utils/existsWithLength';
import { firestore } from '../../../firebase';
import WillLinkToDiv from '../Dashboard/WillLinkToDiv';
import { AdminUp } from '../UserAssignment/checkSecurity';

const QuickAddNote = (props) => {
  const { jctdscid, open, handleClose, location, memorialized } = props;
  const [note, setNote] = useState('');
  const user = useContext(UserContext);
  const role = useRecoilValue(_SecurityLevel);
  const MonthEnd = useRecoilValue(_MonthEndDate);
  const canAddNote = !memorialized || AdminUp(role);

  const handleSave = () => {
    const docRef = doc(collection(firestore, `ENT-Jobs/${jctdscid}/Notes`));
    const noteRecord = {
      MonthEnd,
      NoteAuthor: user.name,
      NoteOwner: user.email,
      Notes: note,
      Role: role,
      jctdscid,
      CreatedDate: new Date().toJSON(),
      CreatedBy: user.email,
      ModifiedDate: new Date().toJSON(),
      ModifiedBy: user.email,
      LinkTo: location,
      id: docRef.id
    };
    if (existsWithLength(note)) setDoc(doc(firestore, `ENT-Jobs/${jctdscid}/Notes/${noteRecord.id}`), noteRecord, { merge: true });
    setNote('');
    handleClose();
  };

  const lockedChildren = [
    {
      content: (
        <Typography>{`This month is memorialized and no new notes can be added. Go to an unlocked month if you want to add a note. `}</Typography>
      ),
      key: 'notice'
    },
    {
      type: 'actions',
      key: 'buttons',
      buttons: [
        {
          text: 'Close',
          onClick: () => {
            setNote('');
            handleClose();
          },
          color: 'error'
        }
      ]
    }
  ];

  const children = [
    {
      content: <InputTextarea name='Note' label='Note' value={note} onBlur={(e) => setNote(e)} />,
      key: 'note textearea'
    },
    {
      content: <WillLinkToDiv type='note' location={location} />,
      key: 'note linkto'
    },
    {
      type: 'actions',
      key: 'buttons',
      buttons: [
        {
          text: 'Cancel',
          onClick: () => {
            setNote('');
            handleClose();
          },
          color: 'error'
        },
        {
          text: 'Save',
          onClick: handleSave
        }
      ]
    }
  ];

  return <AldgModal title='Add Note' open={open} handleClose={handleClose} pieces={canAddNote ? children : lockedChildren} />;
};

QuickAddNote.propTypes = {
  jctdscid: PropTypes.string.isRequired,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  location: PropTypes.string,
  memorialized: PropTypes.bool
};
QuickAddNote.defaultProps = {
  open: false,
  handleClose: () => {},
  location: '',
  memorialized: false
};

export default QuickAddNote;
