/* eslint-disable no-unused-vars */
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const downloadNotesTemplate = async (JobNumber) => {
  const workbook = new ExcelJS.Workbook();

  const TemplateWorksheet = workbook.addWorksheet('Notes');

  TemplateWorksheet.getColumn(3).width = 17;
  TemplateWorksheet.getColumn(4).width = 12;
  TemplateWorksheet.getColumn(3).width = 11;
  TemplateWorksheet.getColumn(4).width = 60;
  TemplateWorksheet.getColumn(5).width = 16;

  TemplateWorksheet.getCell('A1').value = `Company Number`;
  TemplateWorksheet.getCell(`A1`).font = {
    bold: true
  };
  TemplateWorksheet.getCell('B1').value = `Job Number`;
  TemplateWorksheet.getCell(`B1`).font = {
    bold: true
  };
  TemplateWorksheet.getCell('C1').value = `Month End`;
  TemplateWorksheet.getCell(`C1`).font = {
    bold: true
  };
  TemplateWorksheet.getCell('D1').value = `Note`;
  TemplateWorksheet.getCell(`D1`).font = {
    bold: true
  };
  TemplateWorksheet.getCell('E1').value = `Reference Page`;
  TemplateWorksheet.getCell(`E1`).font = {
    bold: true
  };

  // for (let i = 2; i < 100; i++) {
  //   TemplateWorksheet.getCell(`B${i}`).value = {
  //     type: 'list',
  //     allowBlank: true,
  //     formulae: [
  //       'Job Information',
  //       'Agenda',
  //       'Status Report',
  //       'Forecasting',
  //       'Budgets',
  //       'Cashflow',
  //       'Underbilling',
  //       'Notes',
  //       'Action Items',
  //       'User Assignment',
  //       'Attachments',
  //       'Contract Abstract',
  //       'Month Rollback'
  //     ]
  //   };
  // }

  workbook.xlsx.writeBuffer().then((r) => {
    saveAs(new Blob([r.buffer]), `NotesUploadTemplate.xlsx`);
  });
};

export default downloadNotesTemplate;
