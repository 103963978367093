import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { doc, getDocs, collection } from 'firebase/firestore';
import existsWithLength from './existsWithLength';
import { firestore } from '../firebase';

const getCashflowRows = (Transaction, monthEndDate, data, totalPT, setDoc, setRows) => {
  const startMonth = dayjs(Transaction.EcmsStartDate).endOf('month');
  const endMonth = dayjs(
    existsWithLength(Transaction.CashFlowCompletionDate) ? Transaction.CashFlowCompletionDate : Transaction.EcmsOriginalCompletionDate
  ).endOf('month');

  let currentMonth = startMonth;
  dayjs.extend(isSameOrBefore);

  const t1 = [];
  data?.forEach((d, idx) => {
    const currMonthTotal = d.LaborBillings + d.MaterialBillings + d.SubcontractorBillings + d.EquipmentBillings + d.OtherBillings;
    const rec = {
      id: dayjs(d.MonthEndDate).format('MMMM-YY'),
      Month: dayjs(d.MonthEndDate).format('MMMM-YY'),
      ProjectedBillings: d.ProjectBillings,
      ProjectedReceipts: d.ProjectReceipts,
      ProjectedDisbursements: d.ProjectDisbursements,
      Labor: d.LaborBillings,
      Material: d.MaterialBillings,
      Subcontracts: d.SubcontractorBillings,
      Equipment: d.EquipmentBillings,
      Other: d.OtherBillings,
      CurrentMonthTotal: currMonthTotal,
      CumulativeCashPosition: 0,
      MonthCostVBilling: 0,
      PercentComplete: 0,
      RetentionHeld: d.RetentionHeld,
      isActual: true
    };
    rec.CumulativeJobTotal = rec.CurrentMonthTotal + (idx > 0 ? t1[idx - 1].CumulativeJobTotal : 0);
    rec.PercentComplete = rec.CumulativeJobTotal / totalPT;
    rec.CumulativeCashPosition = rec.ProjectedReceipts - rec.ProjectedDisbursements - (idx > 0 ? t1[idx - 1].CumulativeCashPosition : 0);
    rec.MonthCostVBilling = rec.ProjectedBillings - rec.CurrentMonthTotal;
    t1.push(rec);
    setDoc(doc(firestore, `ENT-Jobs/${Transaction.id}/MonthEnd/${monthEndDate}/CashFlow/${rec.id}`), rec, { merge: true });
  });

  getDocs(collection(firestore, `ENT-Jobs/${Transaction.id}/MonthEnd/${monthEndDate}/CashFlow`)).then((snapshot) => {
    const cashFlowDocs = [];
    snapshot.forEach((d) => {
      cashFlowDocs.push(d.data());
    });
    const cashFlowRows = [];
    while (dayjs(currentMonth).isSameOrBefore(dayjs(endMonth), 'month')) {
      const m = dayjs(currentMonth).endOf('month');
      const idx = cashFlowDocs.findIndex((d) => d.id === m.format('MMMM-YY'));
      if (idx > -1) {
        cashFlowRows.push(cashFlowDocs[idx]);
        cashFlowDocs.splice(idx, 1);
      } else {
        const r = {
          id: m.format('MMMM-YY'),
          Month: m.format('MMMM-YY'),
          isActual: false,
          Labor: 0,
          Material: 0,
          Subcontracts: 0,
          Equipment: 0,
          Other: 0,
          CumulativeJobTotal: 0,
          PercentComplete: 0,
          RetentionHeld: 0,
          ProjectedReceipts: 0,
          ProjectedDisbursements: 0,
          CumulativeCashPosition: 0,
          MonthCostVBilling: 0,
          ProjectedBillings: 0
        };
        cashFlowRows.push(r);
        setDoc(doc(firestore, `ENT-Jobs/${Transaction.id}/MonthEnd/${monthEndDate}/CashFlow/${r.id}`), r);
      }
      currentMonth = m.add(1, 'month');
    }
    cashFlowDocs.forEach((d) => {
      const idx = cashFlowRows.findIndex((r) => r.Month === d.Month);
      if (idx > -1) {
        cashFlowRows[idx] = { ...d, ...cashFlowRows[idx] };
      } else {
        cashFlowRows.push(d);
      }
    });
    const sortedRows = cashFlowRows.sort((a, b) => dayjs(a.Month, 'MMMM-YY').diff(dayjs(b.Month, 'MMMM-YY')));
    setRows(sortedRows);
  });
};

export default getCashflowRows;
