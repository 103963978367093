import React, { useState, createContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { TailSpin } from 'react-loader-spinner';
import { httpsCallable } from 'firebase/functions';
import { onAuthStateChanged, getIdToken, getIdTokenResult } from 'firebase/auth';
import { existsWithLength } from '@aldridge/aldg-helpers';
import { collection, collectionGroup, doc, getDoc, getDocs, orderBy, query, where } from 'firebase/firestore';
import { auth, firestore, functions } from '../firebase';

export const UserContext = createContext({ user: null });
const UserProvider = (props) => {
  const [user, setUser] = useState({});
  const { children } = props;
  const [signingIn, setSigningIn] = useState(false);
  useEffect(() => {
    let mounted = true;
    onAuthStateChanged(auth, async () => {
      if (mounted) setSigningIn(true);
      if (auth.currentUser) {
        if (localStorage.getItem('token') !== null && mounted) {
          setUser(JSON.parse(localStorage.getItem('token')));
          setSigningIn(false);
        }
        getIdToken(auth.currentUser, true).then((idToken) => {
          const checkIfUserExists = httpsCallable(functions, 'userExists');
          checkIfUserExists({ idToken, host: window.location.host })
            .then(async () => {
              if (existsWithLength(auth.currentUser)) {
                const token = await getIdTokenResult(auth.currentUser, true);
                if (mounted) {
                  setSigningIn(false);
                  setUser(token.claims);
                }
                localStorage.setItem('token', JSON.stringify(token.claims));
              }
            })
            .catch((err) => {
              toast.error(err.message, { autoClose: 8000 });
            });
        });
      } else if (mounted) {
        localStorage.removeItem('token');
        setSigningIn(false);
        setUser(null);
      }
    });
    return () => (mounted = false);
  }, []);

  useEffect(() => {
    if (existsWithLength(user) && user?.email && user.Jobs === undefined) {
      const allJobs = query(
        collection(firestore, 'ENT-Jobs'),
        where('EcmsSubJobNumber', '==', '   '),
        where('EcmsStatusSimplified', '==', 'Open'),
        orderBy('EcmsJobNumber')
      );
      const specificJobs = query(collectionGroup(firestore, `UserAssignment`), where('Email', '==', user.email));
      getDoc(doc(firestore, 'Users', user.email)).then((d) => {
        const userData = d.data();
        getDocs(allJobs).then((docs) => {
          const all = [];
          docs.forEach((allJobDocs) => {
            const jobinfo = allJobDocs.data();
            all.push(jobinfo);
          });
          getDocs(specificJobs).then((docss) => {
            const allIds = [];
            if (userData.globalRole === 'Division') {
              console.log('userdata', userData);
              userData?.Divisions?.forEach((div) => {
                allIds.push(...all.filter((a) => a.EcmsDivisionNumber === div));
              });
            }
            docss.forEach((specificDocs) => {
              const data = specificDocs.data();
              const idx = all.findIndex((a) => a.id === data.Job[0]);
              const alreadyExistsIdx = allIds.findIndex((a) => a.id === data.Job[0]);
              if (idx > -1 && alreadyExistsIdx === -1) {
                allIds.push({
                  EcmsJobNumber: data.Job[0],
                  id: data.Job[0],
                  CosentialJobName: all[idx].CosentialJobName,
                  EcmsDivisionNumber: all[idx].EcmsDivisionNumber,
                  EcmsJobDescription: all[idx].EcmsJobDescription
                });
              }
            });
            if (user.admin || user.SuperAdmin || (existsWithLength(user.globalRole) && ['none', 'Division'].indexOf(user.globalRole) === -1)) {
              setUser({ ...user, Jobs: all, beta: userData.beta || false });
            } else {
              console.log('jobs filter', allIds);
              setUser({ ...user, Jobs: allIds, beta: userData.beta || false });
            }
          });
        });
      });
    }
  }, [user]);

  return (
    <UserContext.Provider value={user}>
      {signingIn ? (
        <div
          style={{
            display: 'flex',
            position: 'fixed',
            zIndex: 2600,
            backgroundColor: '#fff',
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            opacity: '.70'
          }}
        >
          <TailSpin color='#3b78e7' height={150} width={150} />
          Signing In...
        </div>
      ) : (
        children
      )}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.objectOf(PropTypes.any)
};
UserProvider.defaultProps = {
  children: []
};
export default UserProvider;
