import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { InputDate, RepeatableList } from '@aldridge/aldg-data-components';
import dayjs from 'dayjs';
import InputToggleDate from '../../UIComponents/InputToggleDate';
import InputToggleList from '../../UIComponents/InputToggleList';
import TextDisplay from '../../UIComponents/TextDisplay';

const JobTimeline = (props) => {
  const { Transaction, onChange, editing, user } = props;

  return (
    <Grid container>
      <TextDisplay label='Start Date' name='EcmsStartDate' value={Transaction.EcmsStartDate} />
      <InputToggleDate
        label='First Field Day Of Work'
        name='FirstFieldDayOfWork'
        value={Transaction.FirstFieldDayOfWork}
        onChange={onChange}
        edit={editing}
      />
      <TextDisplay label='Original Completion Date' name='EcmsOriginalCompletionDate' value={Transaction.EcmsOriginalCompletionDate} />
      <InputToggleDate
        label='Substantially Complete'
        name='SubstantiallyComplete'
        value={Transaction.SubstantiallyComplete}
        onChange={onChange}
        edit={editing}
      />
      {Transaction?.EcmsStatus?.toLowerCase().includes('closed') && <TextDisplay label='ECMS Closed Date' value={Transaction.EcmsClosedDate} />}
      <InputToggleList
        name='EcmsRevisedCompletionDate'
        value={
          Array.isArray(Transaction.EcmsRevisedCompletionDate)
            ? Transaction.EcmsRevisedCompletionDate
            : [{ EcmsRevisedCompletionDate: Transaction.EcmsRevisedCompletionDate }]
        }
        edit={editing}
        label='Revised Completion Date'
        RepeatableListComponent={
          <RepeatableList
            breakpoint='500px'
            canAdd
            canDelete
            label='Revised Completion Date'
            keepLabels
            user={user}
            listTransaction={{
              EcmsRevisedCompletionDate: []
            }}
            name='EcmsRevisedCompletionDate'
            onChange={onChange}
            required={false}
            rowObjects={[<InputDate label='Revised Completion Date' name='EcmsRevisedCompletionDate' itemSize={10} />]}
            transactions={
              Array.isArray(Transaction.EcmsRevisedCompletionDate)
                ? Transaction.EcmsRevisedCompletionDate
                : [{ EcmsRevisedCompletionDate: Transaction.EcmsRevisedCompletionDate }]
            }
          />
        }
        format={(val) => (dayjs(val).isValid() ? dayjs(val).format('YYYY-MM-DD') : val)}
      />
    </Grid>
  );
};

JobTimeline.propTypes = {
  Transaction: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
  editing: PropTypes.bool,
  user: PropTypes.object
};
JobTimeline.defaultProps = {
  editing: false,
  user: undefined
};

export default JobTimeline;
